import React from 'react'
import SEO from '../components/SEO/SEO'

export default function PageNotFound404() {
  if (typeof window !== 'undefined') {
    window.location = '/'
  }
  return (
    <>
      <SEO title="404"></SEO>
      <p>Page not found</p>
    </>
  )
}
